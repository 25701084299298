export { default as loadCrud } from '@ax2/xms-crud-module';

import mediacategoriesConfig from './mediacategories.config';
import pagesConfig from './pages.config';
import rolesConfig from './roles.config';
import usersConfig from './users.config';
import customclassesConfig from './customclasses.config';


export default {
  contentLang: ['en'],
  contentLangDefault: 'en',
  types: [
    pagesConfig,
    mediacategoriesConfig,
    usersConfig,
    rolesConfig,
    customclassesConfig,
  ],
};
