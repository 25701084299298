export { default as loadDashboard } from '@ax2/xms-dashboard-module';

export default {
  disablePermissions: true,
  menu: {
    title: 'dashboard.title',
    icon: 'mdi-view-dashboard',
  },
  fields: [
    {
      name: 'ProfileDetails'
    },
    {
      name: 'RecentItems',
      props: {
        type: 'users',
        fields: ['email']
      }
    },
  ]
};
