export default {
  global: {
    primary: '#2865ab',
    accent: '#0076ff',
    background: '#fafafa',
    border: '#0076ff',
    iconFont: 'mdi',
    favicon: 'favicon.png',
  },
  nav: {
    primary: '#1d3557',
    secondary: '#0a2347',
  },
  login: {
    // gradient: {
    //   start: '#0091ea',
    //   end: '#f1f8fE',
    //   rotate: '270deg',
    // },
    logo: 'baum-logo.png',
    splash: 'baum-splashimage.png',
  },
};
